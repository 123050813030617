import { template as template_fcd0f71c26af4cdeac5a9ed6fdc83c46 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_fcd0f71c26af4cdeac5a9ed6fdc83c46(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
