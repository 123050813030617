import { template as template_37790038c97b46e7a54ee4363d2fe05d } from "@ember/template-compiler";
const FKLabel = template_37790038c97b46e7a54ee4363d2fe05d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
