import { template as template_f24e02903178425e905aec6ec7a6e947 } from "@ember/template-compiler";
const WelcomeHeader = template_f24e02903178425e905aec6ec7a6e947(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
