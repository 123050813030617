import { template as template_a84827af8d09424b879c718b029fbfdd } from "@ember/template-compiler";
const EmptyState = template_a84827af8d09424b879c718b029fbfdd(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
