import { template as template_6b3b087a818b4a89bb7cc15cd8d583d2 } from "@ember/template-compiler";
import { on } from "@ember/modifier";
const BulkSelectCell = template_6b3b087a818b4a89bb7cc15cd8d583d2(`
  <td class="bulk-select topic-list-data">
    <label for="bulk-select-{{@topic.id}}">
      <input
        {{on "click" @onBulkSelectToggle}}
        checked={{@isSelected}}
        type="checkbox"
        id="bulk-select-{{@topic.id}}"
        class="bulk-select"
      />
    </label>
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectCell;
